<template>
  <section class="alumni-container">
    <nav class="navbar">
      <span class="navbar-icon">
        <img
          src="https://static.educacionit.com/educacionit/assets/imagotype-it-fill-v2-color.svg"
          alt="Imagotipo de EducacionIT a color"
        />
      </span>
    </nav>

    <div class="alumni-wrapper">
      <main class="main-content">
        <h1 class="alumni-title">Alumni Teacher</h1>
        <button class="btn-login" @click="submitSSO">Iniciar sesión</button>
      </main>

      <footer class="footer">
<!--        <div class="footer-column footer-legend">
          <p>
            ¿Quieres usar el login anterior? Ingresa
            <router-link
              :to="{ name: 'LegacyLogin' }"
            >
              acá
            </router-link>
          </p>
        </div>-->
        <div class="footer-column footer-svg">
          <img
            src="https://static.educacionit.com/alumni/assets/vector-login.svg"
            alt=""
          />
        </div>
      </footer>
    </div>
  </section>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    submitSSO() {
      this.loading = true;
      let auth = this.$store.state.AuthFirebase.auth;

      if (auth.currentUser) {
        this.$router.push({name: "dashboard"});
        return;
      }

      this.$store.dispatch("AuthFirebase/authRedirect", {
        route: this.$route,
        action: "authorize",
      });
    },
  },
};
</script>

<style scoped>
h1 {
  all: unset;
}

.alumni-container {
  font-family: 'Mulish', sans-serif;
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #eef2ff;
}

.alumni-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 280px;
  max-width: 1156px;
  margin-left: auto;
  margin-right: auto;
  height: 95vh;
}

.alumni-title{
  font-size: 36px;
  color: #1d4ed8;
  margin-top: 20px;
  margin-bottom: 20px;
}
.navbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  background-color: white;
  color: white;
  height: 5vh;
}
.navbar-icon {
  min-width: 166px;
  width: 166px;
  min-height: 26px;
  height: 26px;
}
.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  height: 30vh;
}
.btn-login {
  background-color: #15163d;
  color: white;
  border: 1px solid #15163d;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 0;
  transition: all 0.3s ease;
}
.btn-login:hover {
  background-color: white;
  color: #15163d;
  border: 1px solid #15163d;
}
.footer {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 1rem 0 1rem;
  height: 64vh;
}
.footer-column {
  flex: 1;
  padding: 1rem;
  position: relative;
}
.footer-legend {
  align-self: flex-end;
}
.footer img {
  margin-left: auto;
  position: absolute;
  right: 0;
  top: 0;
}
.footer-svg{
  display: none;
}
@media (min-width: 768px) {
  .footer {
    flex-wrap: nowrap;
  }
  .footer-column {
    flex: 1 1 50%;
  }
  .footer-svg{
    display: block;
  }
}
</style>
